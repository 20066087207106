.intro {
    color: $base-0;

    @include grid-media($sm-up) {
        margin-bottom: $spacing-component;
    }
}

.intro__wrapper {
    @include grid-container;
    padding: 45px 0;


    @include grid-media($sm-up) {
        padding: 60px 0;
    }

    &.intro__wrapper--grey-background {
        background-color: $background-grey;
        padding-left: 20px;
        padding-right: 20px;

        @include grid-media($md-up) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.intro__wrapper--first-heading {

        .intro__title {
            font-size: 40px;
            line-height: 38px;

            @include grid-media($md-up) {
                font-size: 44px;
                line-height: 56px;
            }
        }
    }

    &.intro__wrapper--blue-background {
        background-color: $base-0;
        padding-left: 20px;
        padding-right: 20px;

        @include grid-media($md-up) {
            padding-left: 0;
            padding-right: 0;
        }

        .intro__tag,
        .intro__description,
        .intro__title {
            color: $white;
        }

        .intro__title {
            font-size: 40px;
            line-height: 38px;

            @include grid-media($md-up) {
                font-size: 44px;
                line-height: 56px;
            }
        }
    }
}

.intro__col {
    @include grid-column(24);
}

.intro__tag {
    @include grid-column(24);

    @include grid-media($md-up) {
        @include grid-column(22);
        @include grid-shift(1);
    }

}

.intro__title {
    @include grid-column(24);
    color: $base-0;
    font-family: $font-serif;
    // font-size: 40px;
    // line-height: 38px;

    @include grid-media($sm-up) {
        // font-size: 40px;
        // line-height: 38px;
    }

    @include grid-media($md-up) {
        @include grid-column(9);
        @include grid-shift(1);
        // font-size: 32px;
        // line-height: 41px;
    }
}

.intro__description {
    @include grid-column(24);
    color: $base-0;
    font-family: $font-sans;
    // font-size: 16px;
    letter-spacing: 0;
    // line-height: 24px;
    margin-top: 15px;

    .button {
        margin-top: 20px;
    }

    p > a {
        color: $base-1;
    }

    @include grid-media($md-up) {
        @include grid-column(11);
        @include grid-shift(3);
    }
}
