.cards__wrapper {
    // @include grid-column(22);
    margin-bottom: $spacing-component;
    margin-top: 30px;

    @include grid-media($md-up) {
        @include grid-shift(1);
    }
}

.title-container {
    @include grid-column(22);
    margin-top: 60px;

    @include grid-media($md-up) {
        @include grid-column(11);
        @include grid-shift(1);
    }
}

.card {
    @include grid-column(24);
    position: relative;
    // min-height: 300px;
    margin-bottom: 40px;
    overflow: hidden;
    transition: .2s ease-out all;

    a {
        text-decoration: none;
        color: $body-text;
    }
    
    @include grid-media($sm-up) {
        @include grid-column(12);
    }
    
    @include grid-media($md-up) {
        @include grid-column(7);
        margin-right: 30px;
        margin-top: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
    
    &:hover {
        box-shadow: 0px 7px 30px rgba(97, 97, 97, 0.22);
        transform: translateY(-2px);
    }
}

.card__background {
    // position: absolute;
    top: 0;
    // left: 0;
    width: 100%;
    // height: 100%;
    height: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    padding-top: 100%;

    .team &,
    .card--large & {  
        position: relative;
        // height: 320px;
        // padding-top: 0;
        margin-bottom: 120px;
     }
}

.card__content {
    position: absolute;
    z-index: 1;
    background-color: $white;
    bottom: 0;
    padding: 20px 0 0 20px;
    width: 80%;
    right: 0;

    .button {
        float: right;
    }

    .team &,
    .card--large & {
        position: absolute;
        width: calc(100% - 20px);
        min-height: 120px;
        bottom: -120px;
     }
}

.card__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-tag {
        float: right;
    }

    .button {
        margin-right: 20px;
    }
}

.card__title {
    font-size: 19px;
    font-family: $font-serif;
    color: $base-0;
    font-weight: bold;
    margin-bottom: 5px;
}

.card__subtitle {
    font-family: $font-sans;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 10px;
    color: $grey-0;
    margin-bottom: 5px;
}

.card__text {
    font-size: 13px;
    line-height: 18px;
}