.footer {
    background-color: rgba($background-grey, .3);
    padding: 50px 0 20px 0;
}

.footer-top {
    position: relative;
}

.footer__logo {
    position: absolute;
    right :0;
    top: -57px;
}

.footer-content {
    @include grid-container;
}

.footer__links {
    @include grid-column(24);
    
    @include grid-media($sm-up) {
        @include grid-column(12);
    }
    
    @include grid-media($md-up) {
        @include grid-column(5);
    }
    
    &.footer__links--content{
        p {    
            font-size: 14px;
            line-height: 23px;

            a {
                color: $base-3;
            }
        }
    }

    .tag {
        color: $base-0;
        margin-bottom: 15px;
        margin-top: 20px;

        @include grid-media($sm-up) {
            margin-top: 0;
        }
    }

    p {    
        font-size: 14px;
        margin: 0;
    }

    a {
        display: block;
        text-decoration: none;
        font-size: 14px;
        color: $base-0;
        margin-bottom: 4px;

        &:hover {
            color: $base-1;
        }

    }
    
}

.footer__link {
    display: block;
    text-decoration: none;
    font-size: 14px;
    color: $base-0;
    margin-bottom: 4px;
    


    &.footer__link--muted {
        display: inline;
        opacity: .5;
        margin-right: 30px;
        color: $grey-0;
        
        &:last-of-type {
            margin-right: 0;
        }
    }

    &.footer__link--no-hover {
        &:hover {
            color: $grey-0;
        }
    }
    
    &:hover {
        color: $base-1;
    }
}

.footer-bottom {
    @include grid-container;
    border-top: 1px solid rgba($grey-0, .2);
    padding-top: 10px;
    margin-top: 30px;
}

.footer-bottom__col {
    @include grid-column(12);

    &.footer-bottom__col--right {
        text-align: right;
    }
}