.button {
    color: $white;
    padding: 12px 23px;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    transition: .2s ease all;
    text-decoration: none;
    transform: translateX(12px);
    margin-right: 30px;

    svg {
        transform: scale(1.1);
        margin-left: 5px;
        margin-right: 5px;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
        background-color: $base-1;
        transform: skew(-17deg);
        transition: .3s ease all;
    }
    &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
        background-color: $base-2;
        transform: skew(-17deg);
        transition: .4s ease all;
    }

    &:hover {
        // padding: 12px 30px;
        // transform: translateX(0);
        // margin-right: 16px;

        .button__text {
            transform: scale(.99);
        }

        &:before {
            background-color: $base-2;
            // transform: skew(0);
        }
        &:after {
            width: 100%;
        }
    }
}

.button__text {
    position: relative;
    font-family: $font-sans;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 10px;
    z-index: 1;
    display: inline-block;
    transition: .2s ease transform;
}

.button-plain {
    display: inline-block;
    height: 54px;
    line-height: 54px;
    font-family: $font-sans;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 10px;
    color: $base-1;

    &:hover {
        color: $base-2;
        text-decoration: underline;
    }
}
