.text-with-highlights {
    color: $base-0;
    margin-bottom: $spacing-component;
}

.text-with-highlights__wrapper {
    @include grid-container;
    padding: 60px 20px 0;
    background-color: $background-grey;

    @include grid-media($md-up) {
        padding-left: 0;
        padding-right: 0;
    }
}

.text-with-highlights__content {
    @include grid-column(24);
    // @include grid-shift(1);

    @include grid-media($sm-up) {
        @include grid-column(17);
        @include grid-shift(3);
    }

    @include grid-media($md-up) {
        @include grid-column(14);
        @include grid-shift(5);
    }

    .tag {
        text-align: center;
        margin-bottom: 20px;
    }

    .text {
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 30px;

        @include grid-media($sm-up) {
            font-size: 24px;
            line-height: 34px;
        }
    }
}

.text-with-highlights__list {
    @include grid-column(24);
    // @include grid-shift(1);
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 25px 0 0;
    margin-top: 20px;

    @include grid-media($sm-up) {
        @include grid-column(20);
        @include grid-shift(2);
        padding: 40px 0 0;
        justify-content: center;

    }

    .label-item{
        padding: 0 40px;
        color: $black;
        font-size: 12px;
        font-family: $font-serif;
    }
}

.text-with-highlights--aside {
    .text-with-highlights__wrapper {
        max-width: 100%;

        @include grid-media($sm-up) {
            max-width: calc(100% - 60px);
            padding: 40px 0;
        }
    }

    .text-with-highlights__list {
        background: $base-1;
        float: right;
        margin: 0 0 -20px 0;
        padding: 40px 0 26px 0;
        width: 100%;

        @include grid-media($sm-up) {
            margin: 38px 0 0 0;
            width: 340px;
        }

        .label-item {
            color: white;
            font-family: Proxima,serif;
            font-size: 14px;
            width: 100%;
        }

        .tag {
            color: white;
            font-size: 12px;
            line-height: 14px;
        }

        a {
            color: white;
        }
    }

    .text-with-highlights__content {
        left: 0;
        max-width: 100%;

        @include grid-media($sm-up) {
            left: 40px;
            max-width: calc(100% - 400px);
        }

        .tag {
            margin-bottom: 10px;
            text-align: left;
        }
    }
}