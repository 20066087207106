
// *******************************************************************************
// * LAYOUT
// *******************************************************************************

html,
body {
  background-color: $white;

  &.no-scroll {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;

  }
}

body {
  position: relative;

  &:before {
    @include grid-visual(rgba($white, .2));
    bottom: 0;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

figure {
  margin: 0;
}

.container {
  @include grid-container;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $max-width;
  padding-left: $gutter * 1.5;
  padding-right: $gutter * 1.5;
  position: relative;

  @include grid-media($sm-up) {
    padding-left: $gutter * 6;
    padding-right: $gutter * 6;
  }

  @include grid-media($lg-up) {
    padding-left: 0;
    padding-right: 0;
  }
}

.hidden {
  display: none;
}

.lazyload, .lazyloading { opacity: 0; }
.lazyloaded { opacity: 1; -webkit-transition: opacity 1s ease; transition: opacity 1s ease; }