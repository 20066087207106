.qoute {
    @include grid-column(24);

    display: flex;
    margin-bottom: $spacing-component;
    flex-direction: column-reverse;

    @include grid-media($md-up) {
        @include grid-column(20);
        @include grid-shift(2);
        flex-direction: row;
    }

    &.qoute--right {
        @include grid-media($md-up) {
            flex-direction: row-reverse;
        }

        .qoute__author {
            right: auto;
            left: 0;
        }
    }
}

.qoute__content {
    background-color: $base-1;
    color: $black;
    padding: 30px 40px;
    box-sizing: border-box;

    @include grid-media($md-up) {
        padding: 60px 80px;
        width: 50%;
    }

    .tag {
        color: $white;
    }

    h2 {
        margin: 20px 0;
    }

}


.qoute__image {
    position: relative;
    background-size: cover;
    min-height: 240px;

    @include grid-media($sm-up) {
        min-height: 300px;
    }

    @include grid-media($md-up) {
        width: 50%;
        height: auto;
    }
}

.qoute__author {
    background-color: $white;
    padding: 20px;
    right: 0;
    bottom: 0;
    position: absolute;
    opacity: .8;

    @include grid-media($md-up) {
        opacity: 1;
    }
}

.qoute__author-name {
    font-family: $font-serif;
    color: $base-0;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
}
