.content-text { 
    margin-bottom: $spacing-component;
}

.content-text__wrapper {
    @include grid-container;
    
    @include grid-media($md-up) {
        padding-left: 0;
        padding-right: 0;
    }
    
    &.content-text--grey {
        padding: 60px 20px;
        background-color: $background-grey;
    }
}


.content-text__content {
    @include grid-column(24);
    // @include grid-shift(1);
    
    @include grid-media($sm-up) {
        @include grid-column(17);
        @include grid-shift(3);
    }
    
    @include grid-media($md-up) {
        @include grid-column(14);
        @include grid-shift(5);
    }
}

.content-text__text {
    margin-top: 15px;
    line-height: 29px; 

    h2 {
        margin-top: 30px;
        margin-bottom: 15px;
    }
}