.navigation-spacer {
    height: 60px;
}

.navigation {
    // background:  $white;
    background-color: $white;
    position: fixed;
    max-height: 60px;
    // max-height: 60px;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: .3s ease all;

    box-shadow: 0 2px 9px 0 rgba(30,31,90,0.07);

    @include grid-media($md-up) {
    }
    .container {
        height: 100%;
    }

    &.is-collapsed {
        max-height: 800px;

        .navigation__items-extra,
        .navigation__items-main {
            // height: auto;
            opacity: 1;
            max-height: 800px;
            // transition: .3s ease all;
        }

    }
}

.navigation__logo {
    // @include grid-column(3);
    position: absolute;
    z-index: 10;
    margin-top: -10px;

    @include grid-media($md-up) {
        // @include grid-shift(1);
        margin-left: 45px;
    }

    svg {
        margin-left: -9px;
        margin-bottom: -44px;
    }
}

.navigation-item__sub {
    position: absolute;
    background-color: $white;
    width: 100%;
    left: 0;
    border: 1px solid blue;
    display: none;
}


.navigation__items-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 100;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: .3s ease all;

    @include grid-media($md-up) {
        position: relative;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0;
        width: auto;
        height: auto;
        max-height: none;
        opacity: 1;
    }
}

.navigation__items-extra {
    display: flex;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    @include grid-media($md-up) {
        position: relative;
        flex-direction: row;
        margin-left: 40px;
        margin-right: 45px;
        max-height: none;
        opacity: 1;
    }

}

.navigation__items-extra--lang {
    padding-top: 47px;
    margin-left: 0;
    margin-right: 0;
    text-transform: uppercase;

    @include grid-media($md-up) {
        padding-top: 0;
    }

    .navigation-item {
        .navigation-item__title.is-active {
            font-weight: bold;
            color: $base-0;
        }

        @include grid-media($md-up) {
            font-size: 14px;
        }
    }

    .navigation-item:not(:last-child) {
        margin-right: 5px;
    }


  .navigation-item {
    a:first-child::after {
      display: inline-block;
      content: '/';
      color: $grey-0;
    }
  }
}



.navigation__items {
    // @include grid-column(20);
    display: flex;
    min-height: 70px;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include grid-media($md-up) {
        flex-direction: row;
        justify-content: flex-end;
    }
}

.navigation-item {
    display: inline-block;
    font-family: $font-sans;
    letter-spacing: 0.5px;
    font-size: 22px;
    line-height: 47px;
    text-align: center;
    color: $base-0;
    padding: 0 6px;
    border-bottom: 2px solid transparent;
    transition: .2s ease all;

    &.is-active {

        @include grid-media($md-up) {
            border-bottom: 2px solid $base-3;
        }

        .navigation-item__title {
            color: $base-3;
            font-weight: bold;
        }
    }

    @include grid-media($md-up) {
        margin-right: 20px;
        font-size: 16px;
        line-height: auto;
    }
    
    &:hover {
        // border-bottom: 2px solid $base-1;

        .navigation-item__title {
            color: $base-3;
        }
    }
}

.navigation-item__title {
    display: inline-block;
    color: currentColor;
    text-decoration: none;
}


.navigation__trigger {
    position: absolute;
    right: 0;
    top: 15px;
    width: 97px;

    @include grid-media($md-up) {
      display: none;
    }
  }

  .navigation__btn {
    cursor: pointer;
    float: right;
    margin-right: 25px;
    margin-top: 8px;

    @include grid-media($sm-up) {
      margin-right: 30px;
    }


    div {
      background-color: $base-0;
      height: 1px;
      margin-bottom: 4px;
      transition: transform .3s, opacity .3s, background-color .3s;
      width: 18px;

    }

    &.is-active {

      div:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      div:nth-child(2) {
        opacity: 0;
        transform: translateX(-70px);
        visibility: hidden;
      }

      div:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }

    &:hover {
      div:nth-child(1) {
        transform: translateY(-2px);
        transition: transform .1s ease-out;
      }

      div:nth-child(3) {
        transform: translateY(2px);
        transition: transform .1s ease-out;
      }

      // active hover state
      // scss-lint:disable NestingDepth
      &.is-active {
        div:nth-child(1) {
          transform: translateY(5px) rotate(45deg);
        }

        div:nth-child(3) {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
      // scss-lint:enable NestingDepth
    }
  }

  .navigation__word {
    color: $base-0;
    cursor: pointer;
    float: left;
    font-size: 14px;
    // margin-top: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    opacity: 1;
    transform: translateX(0);
    transition: opacity .2s linear, transform .2s ease;

    &.is-active {
      opacity: 0;
      transform: translateX(40px);
    }
  }
