
// *******************************************************************************
// * GRID
// *******************************************************************************

$grid-columns: 24;
$column: 40px;
$gutter: 10px;

$neat-grid: (
  columns: $grid-columns,
  gutter: $gutter
);

$xs: 320px;
$sm: 720px;
$md: 960px;
$lg: 1220px;
$xl: 1420px;

$max-width: 1200px !global;

$xs-up: (
  media: 'only screen and (min-width: #{$xs})'
);
$sm-up: (
  media: 'only screen and (min-width: #{$sm})'
);
$md-up: (
  media: 'only screen and (min-width: #{$md})'
);
$lg-up: (
  media: 'only screen and (min-width: #{$lg})'
);
$xl-up: (
  media: 'only screen and (min-width: #{$xl})'
);
