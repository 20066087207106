.contact {
    @include grid-column(24);
    // @include grid-shift(2);
    display: flex;
    margin-bottom: $spacing-component;
    flex-direction: column;


    @include grid-media($sm-up) {
        flex-direction: row;
    }

    &.contact--has-overlay {
        margin-top: -120px;
    }
}

.contact__content {
    background-color: $white;
    color: $black;
    padding: 20px 0 0 20px;
    box-sizing: border-box;

    @include grid-media($sm-up) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 40px 0 40px 60px;
        width: 60%;
    }

    @include grid-media($md-up) {
        width: 50%;

    }

    h2 {
        margin-bottom: 20px;
    }

}

.contact__list {
    padding: 0;
    margin: 0;

    li {
        line-height: 40px;
        font-size: 16px;
        list-style-type: none;

        a {
            text-decoration: none;
            color: $base-3;
        }

        img {
            margin-right: 15px;
        }
    }
}

.contact__image {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 240px;

    @include grid-media($sm-up) {
        min-height: 300px;
        height: auto;
        width: 40%
    }

    @include grid-media($md-up) {
        width: 50%;
    }

    @include grid-media($md-up) {
    }
}