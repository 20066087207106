
// *******************************************************************************
// * GENERAL
// *******************************************************************************

// Default Sizes
$border-radius: 5px;

$spacing-primary: 10px;
$spacing-secondary: 15px;
$spacing-tertiary: 30px;
$spacing-component: 60px;

// *******************************************************************************
// * COLORS
// *******************************************************************************

// colors
$white: #FFFFFF;
$black: #000000;
$grey-0: #6C6C6C;
$grey-1: #6C6C6C;

$base-0: #1E1F5A;
$base-1: #DDB63F;
$base-2: #C4A139;
$base-3: #42968F;


// State colors
$error-1: #D0021B;

// Text
$body-text: #000000;

$background-grey: #E7E7E7;

// Links
$link-color: #333333;
$link-hover-color: lighten($link-color, 10%);

// Transitions
$duration: 200ms;

