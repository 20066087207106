.breadcrumbs {
    list-style: none;
}

.breadcrumb__item {
    display: inline;
    list-style-type: none;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:after {
        display: inline-block;
        content: '/';
        margin: 0 2px;
        color: $base-3;
    }

    a {
        color: $base-3;
        text-decoration: none;
    }
}

.breadcrumb__item--active {
    &:after {
        display: none;
        content: '';
    }
}
