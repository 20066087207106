.carousel-slide {
    position: relative;
    padding-bottom: 80px;

    @include grid-media($sm-up) {
        padding-bottom: 40px;
    }
}

.carousel__col {
    @include grid-column(24);
    background-color: $base-0;

    @include grid-media($sm-up) {
        @include grid-column(14);
        @include grid-shift(1);
    }

    @include grid-media($md-up) {
        @include grid-column(11);
        @include grid-shift(1);
    }
}
.carousel-image__holder {
    height: 0;
    padding-top: 50%;
    position: relative;

    @include grid-media($sm-up) {
        margin-left: 100px;
        padding-top: 55%;

    }

    @include grid-media($md-up) {
        margin-left: 200px;
        padding-top: 50%;

    }
}

.carousel-item__image {
    background-size: cover;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: .2s ease opacity;

    &.swiper-lazy-loaded {
        opacity: 1;
    }
}

.carousel__content {
    box-sizing: border-box;
    z-index: 5;
    top: 80px;
    position: relative;

    @include grid-media($sm-up) {
        position: relative;
        top: 40px;
        left: 10px;
        right: 10px;
    }

    @include grid-media($md-up) {
        left: auto;
        right: auto;
    }
    @include grid-media($lg-up) {
        left: 0;
        right: 0;
    }
}

.carousel__title-holder {
    padding: 0 15px;

    @include grid-media($sm-up) {
        padding: 0 30px;
    }
}

.carousel__buttons {
    margin-bottom: 50px;

    @include grid-media($md-up) {
        margin-bottom: $spacing-component;
    }
}

.carousel__tag {
    background-color: $base-0;
    color: $white;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: bold;
    display: inline-block;
    margin-top: 50px;
}


.carousel__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 1px;
    color: $white;
    margin-top: 10px;
    margin-bottom: 30px;

    @include grid-media($sm-up) {
        font-size: 32px;
        line-height: 41px;
    }
}


.carousel__qoute {
    position: relative;;
    background-color: $white;
    display: flex;

    @include grid-media($sm-up) {
        padding-left: 30px;
    }
}

.carousel-qoute__author {
    color: $grey-0;
    font-weight: bold;
    padding-top: 20px;
    text-transform: uppercase;
    display: inline-block;
    opacity: 0.5;
    font-size: 12px;
    letter-spacing: 2.4px;
    line-height: 14px;
}

.carousel-qoute__text {
    padding-bottom: 10px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 10px;
}

.carousel-qoute__image {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 140px;
    background-size: cover;
    width: 140px;
    margin-left: 10px;

    @include grid-media($sm-up) {
        flex-basis: 160px;
        width: 160px;
        margin-left: 30px;
    }
}

//
// RELATED SWIPER STYLES
//

.swiper-container-horizontal > .swiper-pagination-bullets {
    position: inherit;
    width: calc(100% - 20px);
    margin: auto;
    padding-top: 20px;
    text-align: right;

    @include grid-media($sm-up) {
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding-top: 0;
    }

    @include grid-media($md-up) {
        position: inherit;
        bottom: 30px;
    }

    .swiper-pagination-bullet-active {
        background-color: $base-0;
    }
}
