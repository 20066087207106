.hero {
    width: 100%;
    position: relative;
    height: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    background-size: cover;
    margin-bottom: $spacing-component;
}

.hero__content {
    @include grid-column(24);
    box-sizing: border-box;
    background-color: $base-0;
    color: $white;
    padding: 30px 20px;
    margin-bottom: 120px;

    @include grid-media($sm-up) {
        @include grid-column(19);
        padding: 40px 30px;
    }

    @include grid-media($md-up) {
        @include grid-column(13);
        padding: 50px 40px;
    }

    @include grid-media($lg-up) {
        @include grid-column(12);
    }

    h1,
    .tag {
        color: $white;
    }
}

.hero__breadcrumbs {
    margin-bottom: 20px;

    @include grid-media($sm-up) {
        padding-left: 50px;
    }
}
