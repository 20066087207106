
// *******************************************************************************
// * TYPOGRAPHY
// *******************************************************************************

$font-serif: 'Lora', sans-serif;
$font-sans: 'Proxima', serif;
$font-size: 18px;
$line-height: $font-size * 1.6;

$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

body {
  color: $body-text;
  font-family: $font-sans;
  font-feature-settings: 'liga' on;
  font-size: $font-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: common-ligatures;
  font-weight: $font-regular;
  line-height: $line-height;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: $font-serif;
  color: $base-0;
}

h1, .h1 {
  font-size: 26px;
  letter-spacing: 1.38px;
  line-height: 34px;

  @include grid-media($sm-up) {
    font-size: 44px;
    line-height: 56px;
  }
}

h2, .h2{
  font-size: 32px;
  letter-spacing: 1px;
  line-height: 41px;
}


p {
  margin: 0 0 ($line-height * .5);
}

hr {
  border: 0;
  border-bottom: 1px solid $black;
  margin: $line-height 0;
}

img {
  margin: 0;
  max-width: 100%;
}

abbr,
acronym {
  border-bottom: 1px dotted $black;
  cursor: help;
}

address {
  display: block;
  margin: 0 0 ($line-height / 2);
}

hgroup {
  margin-bottom: $line-height / 2;
}

del {
  color: lighten($body-text, 15);
}

blockquote {
  border-left: 2px solid $black;
  color: lighten($body-text, 15);
  margin: $line-height 0;
  padding-left: $line-height / 2;
}

cite {
  color: lighten($body-text, 25);
  font-style: italic;

  &:before {
    content: '\2014 \00A0';
  }
}
