.label-tag {
    background-color: $base-0;
    color: $white;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 2px;
    text-align: center;
    padding: 5px 10px;
    text-transform: uppercase;
    text-decoration: none;
}

a.label-tag {
    &:hover {
        background-color: $base-1;
    }
}