.filters {
    @include grid-column(22);
    
    @include grid-media($md-up) {
        @include grid-shift(1);

    }
}

.filter {
    display: inline-block;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 2px;
    text-align: center;
    padding: 4px 15px;
    text-transform: uppercase;
    color: rgba($grey-0, .5);
    border: 1px solid rgba($grey-0, .5);
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s ease all;
    margin-right: 0;
    background-color: $white;

    &:active,
    &:hover,
    &.is-active {
        color: $white;
        background-color: $base-3; 
        border: 1px solid rgba($base-3, 1);
        outline: 0;       
    }
}